import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Avatar,
  CircularProgress,
  CssBaseline,
  Paper,
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Stepper,
  Step,
  StepLabel,
  StepContent
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import { PDFDownloadLink } from '@react-pdf/renderer';
import axios from 'axios';
import { dateDiff } from '../../../utils/formatDate';
import { PDFDocument } from '../../../components/pdf';
import { axiosPrivate } from '../../../common/axiosPrivate';

// Create Document Component
const DetailPegawaiKeluarPage = ({id}) => {
  const [pageLoading, setPageLoading] = useState(true);
  const [pegawai, setPegawai] = useState();
  const [promosi, setPromosi] = useState([]);
  const [mutasi, setMutasi] = useState([]);
  const [penghargaan, setPenghargaan] = useState([]);

  const getDateDiff = (startDate, endDate) => {
    const { years, months, days } = dateDiff(startDate, endDate);

    let result = '';
    if (years > 0) {
        result += `${years} Tahun `;
    }
    if (months > 0) {
        result += `${months} Bulan `;
    }
    if (days > 0) {
        result += `${days} Hari `;
    }

    return result.trim(); // Remove trailing space
  };

  const formatDate = (TAHUN, BULAN) => {
    const formattedDate = TAHUN && BULAN 
      ? format(new Date(`${TAHUN}-${BULAN}`), 'MMM yyyy') 
      : '';

    return formattedDate;
  }

  const getKategori = (kategori) => {
    switch (kategori) {
      case "1":
        return "Kinerja Petugas Simpanan Terbaik";
      case "2":
        return "Kinerja Petugas Pendapatan Terbaik";
      case "3":
        return "Kinerja Petugas Penyaluran Terbaik";
      case "4":
        return "Kinerja Petugas Infaq Terbaik";
      case "5":
        return "Kinerja Petugas TKB Terbaik";
      default:
        return "Kategori Tidak Dikenal";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileResponse = await axiosPrivate.get(`/pegawai-keluar/profile/${id}`);
        const data = profileResponse?.data;
  
        try {
          const awardsResponse = await axios.get(`${process.env.REACT_APP_SIRKAH_API_URL}/master/penghargaan-bulanan/0000/${data?.kodePegawai}`);
          setPenghargaan(awardsResponse?.data);
        } catch (error) {
          console.log(error);
        }
  
        setPegawai(data);
        setPromosi(data?.promosiData);
        setMutasi(data?.mutasiData);
      } catch (error) {
        console.log(error?.response);
      } finally {
        setPageLoading(false);
      }
    };
  
    fetchData();
  }, [id]);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Paper elevation={3}>
        <Card>
          <CardContent>
            {pageLoading ? (
              <Box
                sx={{
                  flexGrow: 1,
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={"100%"}
                width={"100%"}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar
                          alt={pegawai?.profilUrlId}
                          src={pegawai?.profilUrlId ? `https://drive.google.com/thumbnail?id=${pegawai?.profilUrlId}` : ''}
                          sx={{ width: 150, height: 150 }}
                          imgProps={{ referrerPolicy: "no-referrer" }}
                        />
                        <div>
                          <Typography variant="h4">
                            {pegawai.nama}
                          </Typography>
                          <Typography variant="subtitle2">
                            {pegawai.kodePegawai}
                          </Typography>
                        </div>
                      </Stack>
                    </>
                    <PDFDownloadLink
                      document={<PDFDocument data={{ pegawai, promosi, mutasi, penghargaan }} />}
                      fileName="pegawai_detail.pdf"
                    >
                      {({ loading }) =>
                        loading ? 'Generating PDF...' : 'Download PDF'
                      }
                    </PDFDownloadLink>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Profil</Typography>
                  <Table size="small" aria-label="Profile">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Nama Tanpa Gelar</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.namaTanpaGelar}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Jenis Kelamin</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.jenisKelamin}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Tempat Lahir</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.tempatLahir}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Tanggal Lahir</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.tglLahir ? format(parseISO(pegawai.tglLahir), 'dd MMM yyyy') : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Status Perkawinan</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.statusPerkawinan}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Pendidikan</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.pendidikan}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Agama</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.agama}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Kecamatan</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.kecamatan}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Kota/Kabupaten</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.kotaKabupaten}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Alamat</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.alamat}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Akun</Typography>
                  <Table size="small" aria-label="Profile">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">No. Rekening</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.noRekening}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">No. Handphone</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.noHp}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">NIK</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.nik}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">NPWP</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.npwp}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">BPJS</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.bpjs}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Email</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.email}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6">Kepegawaian</Typography>
                  <Table size="small" aria-label="Profile">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Tanggal Masuk</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.tglMasuk ? format(parseISO(pegawai.tglMasuk), 'dd/MM/yyyy') : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Kantor</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.kantor}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Jabatan</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.jabatan}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Status Pegawai</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.statusPegawai}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Tanggal Status</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.tglStatus ? format(parseISO(pegawai.tglStatus), 'dd/MM/yyyy') : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Lama Kerja</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{getDateDiff(format(parseISO(pegawai.tglMasuk), 'yyyy-MM-dd'), format(parseISO(pegawai.tglKeluar), 'yyyy-MM-dd'))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Tanggal Keluar</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.tglKeluar ? format(parseISO(pegawai.tglKeluar), 'dd/MM/yyyy') : ''}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Sebab Keluar</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.sebab}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ width: 160 }} align="left">Keterangan Keluar</TableCell>
                        <TableCell sx={{ width: 4 }} align="center">:</TableCell>
                        <TableCell align="left">{pegawai.keterangan}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Paper>

      {promosi.length > 0 && <Paper sx={{mt: 4}} elevation={3}>
        <Card>
          <CardContent>
            <Typography variant="h6">Promosi/Demosi</Typography>
            <Box sx={{ maxWidth: 400 }}>
              <Stepper orientation="vertical">
                {promosi.map((item) => (
                  <Step active key={item._id}>
                    <StepLabel
                      optional={
                        <Typography variant="caption">
                          {item.tglMulai ? format(parseISO(item.tglMulai), 'dd MMM yyyy') : ''}
                        </Typography>
                      }
                    >
                      {item.keJabatan}
                    </StepLabel>
                    <StepContent>
                      <Typography variant="body2">
                        {`Promisi/Demosi dari jabatan ${item.dariJabatan} ke jabatan ${item.keJabatan}`}
                      </Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </CardContent>
        </Card>
      </Paper>}

      {mutasi.length > 0 && <Paper sx={{mt: 4}} elevation={3}>
        <Card>
          <CardContent>
            <Typography variant="h6">Mutasi</Typography>
            <Box sx={{ maxWidth: 400 }}>
              <Stepper orientation="vertical">
                {mutasi.map((item) => (
                  <Step active key={item._id}>
                    <StepLabel
                      optional={
                        <Typography variant="caption">
                          {item.tglMulai ? format(parseISO(item.tglMulai), 'dd MMM yyyy') : ''}
                        </Typography>
                      }
                    >
                      {item.keKantor}
                    </StepLabel>
                    <StepContent>
                      <Typography variant="body2">
                        {`Mutasi dari kantor ${item.dariKantor} ke kantor ${item.keKantor}`}
                      </Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </CardContent>
        </Card>
      </Paper>}

      {penghargaan.length > 0 && <Paper sx={{mt: 4}} elevation={3}>
        <Card>
          <CardContent>
            <Typography variant="h6">Penghargaan</Typography>
            <Box sx={{ maxWidth: 400 }}>
              <Stepper orientation="vertical">
                {penghargaan.map((item) => (
                  <Step active key={item.KODE}>
                    <StepLabel
                      optional={
                        <Typography variant="caption">
                          {formatDate(item.TAHUN, item.BULAN)}
                        </Typography>
                      }
                    >
                      {item.keKantor}
                    </StepLabel>
                    <StepContent>
                      <Typography variant="body2">
                        {`Penghargaan ${getKategori(item.KATEGORI)} kantor ${item.NMKANT}`}
                      </Typography>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </CardContent>
        </Card>
      </Paper>}
    </Container>
  )
};

export default DetailPegawaiKeluarPage;
